<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        {{ `${$_t("attribute.classroomNumber")} : ${time.classroomNumber}` }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{
          `${$_t("attribute.start_date")} : ${$_date(
            time.start * 1000
          )} => ${getMainTime(time.start * 1000, "h:mm a")} : ${getMainTime(
            time.end * 1000,
            "h:mm a"
          )} `
        }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <!--  state="Cancel" -->
      <v-btn color="primary" :disabled="disabled" @click="openSurveyModal">{{
        $_t("attribute.setSurvey")
      }}</v-btn>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
import { getMainTime } from "@/tools/Utils";
import { mapGetters } from "vuex";
export default {
  name: "StudentClassSessionModalItem",
  data() {
    return {
      getMainTime,
    };
  },
  props: {
    time: {
      required: true,
      type: Object,
    },
    classroom: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
    disabled() {
      //if user set survey can not add submit survey
      if (this.time.survey) {
        const survey = this.time.survey.find(
          (s) => s.studentPublicKey === this.user.publicKey
        );
        if (survey) return true;
      }
      //if user was absent can not submit survey
      const info = this.classroom.presenceInfo.find(
        (info) =>
          info.availableTimeId === this.time.availableTimeId &&
          info.start === this.time.start &&
          info.end === this.time.end
      );
      if (info) {
        if (!info.isPresent) return true;
      }
      // if admin do not confirmed session or session was canceled student can not submit survey
      return (
        !this.time.changesConfirmedBySchedulingAdmin ||
        this.time.state === "Cancel"
      );
    },
  },
  methods: {
    openSurveyModal() {
      this.$emit("openSurvey", this.time);
    },
  },
};
</script>

<style scoped></style>
