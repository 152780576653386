<template>
  <v-dialog persistent v-model="surveyModal" width="800">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card v-if="questions.length" dark>
        <v-container>
          <v-row v-for="question in questions" :key="question.id">
            <v-col cols="12">
              <v-card-title>
                {{ question.title }}
              </v-card-title>
            </v-col>
            <v-col cols="12">
              <v-radio-group v-model="question.rate" row>
                <v-radio
                  :label="$_t('attribute.veryWeak')"
                  color="primary"
                  :value="1"
                ></v-radio>
                <v-radio
                  :label="$_t('attribute.weak')"
                  color="primary"
                  :value="2"
                ></v-radio>
                <v-radio
                  :label="$_t('attribute.medium')"
                  color="primary"
                  :value="3"
                ></v-radio>
                <v-radio
                  :label="$_t('attribute.good')"
                  color="primary"
                  :value="4"
                ></v-radio>
                <v-radio
                  :label="$_t('attribute.veryGood')"
                  color="primary"
                  :value="5"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                full-width
                v-model="moreDetails"
                :label="$_t('attribute.details')"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="loading" text @click="close">
            {{ $_t("attribute.close") }}
          </v-btn>
          <v-btn color="primary" :disabled="loading" @click="submit">
            {{ $_t("attribute.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import message from "@/tools/Message";

export default {
  name: "StudentSurveyModal",
  props: {
    time: {
      type: Object,
      required: true,
    },
    classroom: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      surveyModal: false,
      questions: [],
      moreDetails: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
      loading: "loading/is_loading",
    }),
  },
  watch: {
    async surveyModal(value) {
      if (value) {
        this.questions = [];
        const res = await this.$actions.getSurveyQuestions();
        for (const id in res) {
          this.questions.push({ id, title: res[id], rate: null });
        }
      }
    },
  },
  methods: {
    async submit() {
      const notAnswered = this.questions.find((q) => q.rate === null);
      if (notAnswered) return message.error(this.$_t("FILL_CORRECT"));
      const payload = {
        classroomId: this.classroom.classroomId,
        availableTimeId: this.time.availableTimeId,
        start: this.time.start,
        end: this.time.end,
        survey: {
          studentPublicKey: this.user.publicKey,
          surveys: this.questions.map((q) => ({
            questionId: q.id,
            score: q.rate,
          })),
          moreDetails: this.moreDetails,
          confirmedByStudent: true,
        },
      };
      console.log(payload);
      const res = await this.$actions.submitStudentSurvey(payload);
      if (res) {
        this.close();
        await this.$actions.getClassroomsOfUser({
          publicKey: this.user.publicKey,
          dispatch: "class/setClassroomsOfUser",
        });
      }
    },
    open() {
      this.surveyModal = true;
    },
    close() {
      this.questions = [];
      this.moreDetails = "";
      this.surveyModal = false;
    },
  },
};
</script>

<style scoped></style>
