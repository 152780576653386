<template>
  <v-dialog v-model="timesModal" width="800">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card v-if="times" dark>
        <StudentClassSessionModalItem
          v-for="(time, index) in times"
          :key="index"
          :time="time"
          :classroom="singleClass"
          @openSurvey="openSurveyModal"
        />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="timesModal = false">
            {{ $_t("attribute.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <StudentSurveyModal
        :time="time"
        :classroom="singleClass"
        ref="surveyModal"
      />
    </template>
  </v-dialog>
</template>

<script>
import StudentSurveyModal from "@/packages/Reports/components/StudentSurveyModal";
import StudentClassSessionModalItem from "@/packages/Reports/components/StudentClassSessionModalItem";

export default {
  name: "StudentClassSessonModal",
  components: { StudentClassSessionModalItem, StudentSurveyModal },
  data() {
    return {
      timesModal: false,
      time: {},
    };
  },
  props: {
    times: {
      type: Array,
      required: true,
    },
    singleClass: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openSurveyModal(time) {
      this.time = time;
      this.$refs.surveyModal.open();
    },
  },
};
</script>

<style scoped></style>
