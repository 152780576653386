<template>
  <v-card :loading="loading" dark class="mx-auto">
    <v-img src="@/assets/img/myClass.jpg" height="200px"></v-img>
    <v-card-title>
      {{ `${$_t("attribute.classroomName")}: ${singleClass.classroomName}` }}
    </v-card-title>

    <v-card-subtitle class="pb-0">
      {{
        `${$_t("attribute.teacher")}: ${singleClass.teacherName}`
      }} </v-card-subtitle
    ><v-card-subtitle class="pb-0">
      {{
        `${$_t("attribute.classroomLevel")}: ${$_t(
          `attribute.${singleClass.classroomLevel}`
        )}`
      }}
    </v-card-subtitle>
    <v-card-subtitle class="pt-0">
      {{
        `${$_t("attribute.baseType")}: ${$_t(
          `attribute.${singleClass.baseType}`
        )}`
      }}
    </v-card-subtitle>
    <v-card-actions>
      <StudentClassSessonModal
        :single-class="singleClass"
        :times="singleClass.times"
      >
        <template #activator>
          <v-btn color="#fff" text> Sessions </v-btn>
        </template>
      </StudentClassSessonModal>

      <v-spacer></v-spacer>

      <v-btn icon @click="getInfo">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text v-for="(charge, index) in charges" :key="index">
          <div>
            {{
              `${$_t("attribute.remaningChargeInHour")}: ${
                charge.remaningChargeInHour
              } h`
            }}
          </div>
          <div>
            {{
              `${$_t("attribute.shouldChargeInHour")}: ${
                charge.shouldChargeInHour
              } h`
            }}
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getMainTime } from "@/tools/Utils";
import StudentClassSessonModal from "@/packages/Reports/components/StudentClassSessonModal";

export default {
  name: "StudentClass",
  components: { StudentClassSessonModal },
  props: {
    singleClass: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      loading: "loading/is_loading",
    }),
  },
  data() {
    return {
      show: false,
      charges: [],
      getMainTime,
    };
  },
  methods: {
    async getInfo() {
      if (this.show) {
        this.show = !this.show;
      } else {
        this.charges = await this.$actions.studentChargeByClassroomId({
          classroomId: this.singleClass.classroomId,
        });
        this.show = !this.show;
      }
    },
  },
};
</script>

<style scoped></style>
