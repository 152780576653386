<template>
  <v-container>
    <v-row v-if="classes.length">
      <v-col cols="4" v-for="(singleClass, index) in classes" :key="index">
        <StudentClass :singleClass="singleClass" />
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <p class="text-center white--text pt-10 text-h4 justify-center">
        {{ $_t("attribute.class_empty") }}
      </p>
    </v-row>
  </v-container>
</template>

<script>
import StudentClass from "@/packages/Reports/components/StudentClass";
import { mapGetters } from "vuex";
export default {
  name: "class",
  components: { StudentClass },
  computed: {
    ...mapGetters({
      classes: "class/classrooms",
      user: "authentication/user",
    }),
  },
  methods: {
    async init() {
      await this.$actions.getClassroomsOfUser({
        publicKey: this.user.publicKey,
        dispatch: "class/setClassroomsOfUser",
      });
      // await this.$actions.sumOfStudentDeposits({
      //   publicKey: this.user.publicKey,
      // });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped></style>
